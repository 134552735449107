<template>
  <Card class="card px-2 text-jet flex flex-col justify-around">
    <div class="flex justify-start items-center gap-2">
      <p class="text-xs uppercase font-black">{{ type }}</p>
      <icon v-if="type === `Variance`" icon-name="growth-icon" size="xs"/>
    </div>
    <div class="w-full flex justify-between items-center">
      <div class="flex w-2/3 justify-start items-center gap-2">
        <icon :icon-name="iconName" class="width" size="xs"/>
        <div>
          <p class="font-black size uppercase">{{ grossTitle }}</p>
          <p class="text-base font-semibold">{{ grossSalary ? convertToCurrency(grossSalary) : "NO"  }}</p>
        </div>
      </div>
      <div class="flex w-1/3 justify-start items-center gap-2">
        <div class="h-5 border border-solid border-jet"></div>
        <div>
          <p class="font-black size uppercase">{{ empTitle }}</p>
          <p class="text-base font-semibold">{{  empCount ? empCount : 0 }}</p>
        </div>
      </div>
    </div>
  </Card>
</template>

<script>
import Card from "@/components/Card";

export default {
  name: "TrendState",
  props: {
    type: String,
    grossTitle: String,
    grossSalary: Number,
    empTitle: String,
    empCount: Number,
    iconName: String
  },
  components: {
    Card,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.card{
  width: calc(100% / 3) !important;
  height: 82px !important;
}
.width{
  width: 20px !important;
  height: 22px !important;
}
.size{
  font-size: 10px;
}
</style>
